import { Icommon } from '@models/general';
import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';

const AdminModule: Module<Icommon, any> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default AdminModule;
