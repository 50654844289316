import { Icommon } from '@models/general';
import { GetterTree } from 'vuex';

export const getters: GetterTree<Icommon, any> = {
  visibleAnnouncement: state => {
    return (
      !!state.announcement?._id &&
      (state.announcement.viewed_by === 'admin' ||
        state.announcement.viewed_by === 'all')
    );
  },
  colorAnnouncement: state => {
    switch (state.announcement?.category) {
      case 'warning':
        return 'warning';
      case 'alert':
        return 'danger';
      case 'success':
        return 'success';
      case 'informational':
        return 'info';
      case 'neutral':
        return 'neutral';
      default:
        return 'neutral';
    }
  }
};
