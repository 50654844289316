import { szRoutes } from '@helpers/SzRoutes';
import { szRules } from '@helpers/SzRules';
import { loadLayout, loadView } from '@helpers/utils';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: szRoutes.login.default,
    name: 'signin',
    component: loadView('signin/Signin.vue')
  },
  {
    path: '/oauth/saml/:token',
    name: 'saml',
    component: loadView('signin/VerifyAuthentication.vue')
  },
  {
    path: '/oauth/supportRemote/:token',
    name: 'authSupportRemote',
    component: loadView('signin/VerifyAuthentication.vue')
  },
  {
    path: szRoutes.login.forgot_password,
    name: 'forgot_password',
    component: loadView('signin/ForgotPassword.vue')
  },
  {
    path: szRoutes.login.redefine_password,
    name: 'redefine_password',
    component: loadView('signin/RedefinePassword.vue')
  },
  {
    path: '/',
    component: loadLayout('Layout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'indicators',
        meta: { requiresAuth: true },
        component: loadView('admin/Dashboard/Indicators.vue')
      },
      {
        path: szRoutes.channels.default,
        name: 'channels',
        meta: {
          requiresAuth: true,
          view_permission: Object.keys(szRules.register.channels)[0],
        },
        component: loadView('admin/Channel/Channel.vue'),
      },
      {
        path: szRoutes.marketplace.default,
        name: 'marketplace',
        meta: {
          requiresAuth: true,
          view_permission: Object.keys(szRules.services.marketplace)[0]
        },
        component: loadView('admin/Marketplace/Marketplace.vue')
      },
      {
        path: szRoutes.settings.hsm_template.default,
        name: 'hsm_template',
        meta: {
          requiresAuth: true,
          view_permission: Object.keys(szRules.configurations.hsm_template)[0]
        },
        component: loadView('admin/Hsm/Hsm.vue')
      },
      {
        path: szRoutes.settings.administrators.default,
        name: 'admins',
        meta: {
          requiresAuth: true,
          view_permission:
            Object.keys(szRules.register['rules.admins.name'].admins)[0]

        },
        component: loadView('admin/Admin/Admin.vue'),
      },
      {
        path: szRoutes.settings.time_group.default,
        name: 'timeGroup',
        meta: {
          requiresAuth: true,
          view_permission:
            Object.keys(szRules.configurations.scheduler)[0] 
        },
        component: loadView('admin/TimeGroup/TimeGroup.vue'),
      },
    ],
  },
  //Active contact modal
  {
    path: szRoutes.active_contact.default,
    name: 'active-contact',
    meta: {
      hasAuthenticationTokenWithoutValidatingPermission: true,
    },
    component: loadView('active-contact/ActiveContactModal.vue')
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    name: '404',
    component: loadView('404.vue')
  },
  {
    path: '/403',
    name: '403',
    component: loadView('403.vue')
  }
];

export default routes;
