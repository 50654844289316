import {
  getUserLoggedIn,
  setSessionSaml,
  setSessionSupportRemote
} from '@helpers/utils';
import { Icommon } from '@models/general';
import { route } from 'quasar/wrappers';
import VueRouter from 'vue-router';
import { removeSessionUser } from '../helpers/utils';
import routes from './routes';

export const $route = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
});

export default route(({ Vue, store }) => {
  Vue.use(VueRouter);

  $route.beforeEach(
    async (to, from, next): Promise<void> => {
      const { name, matched, meta, params, query } = to;
      const UserLogged: Icommon = getUserLoggedIn();
      const sessionExpired = query?.action === 'session_expired';

      if (matched.some(record => record.meta.requiresAuth)) {
        if (name !== 'signin' && meta.requiresAuth && !UserLogged.token) {
          next({ name: 'signin' });
        } else {
          await store.dispatch('SettingsModule/REQUEST_CONFIG_INITIAL', meta);
          next();
        }
      }
      else if (meta.hasAuthenticationTokenWithoutValidatingPermission && !UserLogged.token) {
        next({ name: '403' });
      }
      // checa se o usuário tá logado e foi para tela de login
      else if (name === 'signin' && UserLogged.token) {
        // se a sessão do laravel expirar remove a sessão
        if (sessionExpired) {
          removeSessionUser();
        }
        // se existir sessão retorna para dashboard
        else {
          location.href = UserLogged.intend;
        }
      }
      // checa se é autenticação do suporte remoto
      else if (name === 'authSupportRemote') {
        next();
        if (params.token) {
          setSessionSupportRemote(params.token);
        } else {
          removeSessionUser();
        }
      }
      // checa se é o login do saml para cria a sessão do usuário
      else if (name === 'saml') {
        next();

        if (params.token) {
          setSessionSaml(params.token);
        } else {
          removeSessionUser();
        }
      } else {
        next();
      }
    }
  );

  return $route;
});
