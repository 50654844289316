import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_OPEN_SIDEBAR_LEFT(state) {
    state.sidebar.left = !state.sidebar.left;
  },
  SET_DARK(state, value: boolean) {
    state.dark = value;
  },
  SET_LANGUAGE(state, value: string) {
    state.lang = value;
  }
};
