import { $apollo } from '@boot/apollo';
import { query } from '@graphql';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  async GET_LIST_SELECT_FIELDS({ commit }): Promise<void> {
    await $apollo
      .query({ query: query.GET_LIST_SELECT_FIELDS })
      .then(({ data: { additionalFields } }) => {
        const { items, meta } = additionalFields;
        commit('SET_LIST_SELECT_FIELDS', items);
        commit('SET_PAGINATION_SELECT_FIELDS', meta);
      });
  },
  async SEARCH_FIELDS({ commit }, search): Promise<void> {
    await $apollo
      .query({ query: query.SEARCH_FIELDS, variables: { search } })
      .then(({ data: { additionalFields, predefinedStaticFields } }) => {
        const { items } = additionalFields;
        const additional = items;
        let predefined = predefinedStaticFields;
        if (search) {
          predefined = predefined.filter(el => {
            return el.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
          });
        }
        const fields = [...predefined, ...additional];
        commit('SET_LIST_SELECT_FIELDS', fields);
      });
  },
  async VIRTUAL_SCROLL_FIELDS({ state, commit }, { direction, to, ref }) {
    const lastIndex = state.select_additional_fields.length - 1;
    const pagination = state.pagination.selectAdditionalFields;

    if (
      state.loadingSelect !== true &&
      direction === 'increase' &&
      to === lastIndex
    ) {
      const limit = pagination.itemsPerPage;
      const page_next = +pagination.currentPage + 1;

      let page = 0;

      if (page_next <= pagination.totalPages) {
        page = page_next;
      } else {
        return false;
      }

      commit('SET_LOADING_SELECT', true);

      await $apollo
        .query({
          query: query.GET_FIELDS_PAGINATION,
          variables: {
            page,
            limit
          }
        })
        .then(({ data: { additionalFields } }) => {
          commit('ADD_LIST_SELECT_FIELDS', additionalFields.items);
          commit('SET_PAGINATION_SELECT_FIELDS', additionalFields.meta);
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
          ref.refresh();
        });
    }
  }
};
