import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  SET_LIST_PAGES_POST(state, value) {
    state.listPagePost = value;
  },
  SET_LIST_PAGES_MESSENGER(state, value) {
    state.listPageMessenger = value;
  }
};
