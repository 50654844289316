import { getUserLoggedIn, renderPhotoAdmin } from '@helpers/utils';
import { Icommon } from '@models/general';
import { GetterTree } from 'vuex';

export const getters: GetterTree<Icommon, any> = {
  user: () => {
    const { user }: Icommon = getUserLoggedIn();

    user.photo = renderPhotoAdmin(user?._id, user?.photo)

    return {
      ...user
    };
  }
};
