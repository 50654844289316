import { Icommon } from '@models/general';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { state } from './state';

const SettingsModule: Module<Icommon, any> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default SettingsModule;
