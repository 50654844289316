import { generatePaginate } from '@helpers/utils';
import { Icommon, IPagination } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  OPEN_MODAL_REGISTER(state, value: boolean) {
    state.openModalRegister = value;
  },
  CLEAR_RA_PAGES(state) {
    state.listRaPages = [];
  },
  OPEN_MODAL_RULES(state, value: Icommon) {
    state.openModalRules = {
      openModal: value.openModal || false,
      id: value.id || ''
    };
  },
  OPEN_MODAL_CUSTOMIZATION(state, value: Icommon) {
    state.openModalCustomization = {
      openModal: value.openModal || false,
      channel: value.channel || {}
    };
  },
  OPEN_MODAL_QRCODE(state, value: Icommon) {
    state.infoQrcode = {
      openModal: value.openModal || false,
      id: value.id || ''
    };
  },
  OPEN_MODAL_ACTIVATION(state, value: Icommon) {
    state.webChatAtivation = {
      openModal: value.openModal || false,
      id: value.id || '',
      version: value.version || 'V1'
    };
  },
  OPEN_MODAL_TEST_SCRIPT(state, value: Icommon) {
    state.modalTestScript = value;
  },
  SET_STATUS_QRCODE(state, value: Icommon) {
    const position = state.listStatus.findIndex(
      item => item.channel_id === value.channel_id
    );
    // adding the status list
    if (position > -1) {
      state.listStatus[position].qrcode = value.qrcode;
    } else {
      state.listStatus.push(value);
    }

    state.listStatus = [...state.listStatus];

    const allChannels = state.listChannels;
    // updating the channel list
    const { status, channel_id } = value;
    const i = allChannels.findIndex(({ _id }) => _id === channel_id);

    // check if the qrcode modal is open with this id
    const index = allChannels.findIndex(
      ({ _id }) => _id === state.infoQrcode.id
    );

    if (value?.status === 'connected' && index > -1) {
      state.infoQrcode = {
        openModal: false,
        id: ''
      };
    }

    if (i > -1) {
      const channel = state.listChannels[i];
      state.listChannels[i] = { ...channel, status };
      state.listChannels = [...state.listChannels];
    }
  },
  SET_AD_ACCOUNT_PAGES(state, value) {
    state.listAccountPages = value;
  },
  SET_PLATFORM_ACTIVE(state, value) {
    state.platformActive = value;
  },
  SET_LIST_PLATFORMS(state, value) {
    state.listPlatforms = value;
  },
  SET_LIST_BSPS(state, value) {
    state.listBsps = value;
  },
  SET_FORTICS_BSPS(state, value: Icommon[]) {
    state.forticsBsps = value;
  },
  SET_TECH_PROVIDERS_BSPS(state, value: Icommon[]) {
    state.techProviderBsps = value;
  },
  SET_LIST_CHANNELS(state, value) {
    state.listChannels = [...value];
  },
  ADD_ITEM_SELECT_CHANNELS(state, value) {
    state.listChannels.push({ ...value });
  },
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  SET_LOADING(state, value: boolean) {
    state.loading = value;
  },
  SET_LOADING_MODAL(state, value: boolean) {
    state.loadingModal = value;
  },
  SET_SEND(state, value) {
    state.sendForm = value;
  },
  SET_RA_PAGES(state, value) {
    state.listRaPages = value;
  },
  SET_UPDATE_LIMIT_PAGINATION(state, value: number) {
    state.pagination.channels.itemsPerPage = value;
  },
  SET_PAGINATION_CHANNELS(state, value: IPagination) {
    state.pagination.channels = generatePaginate(value);
  }
};
