import { $apollo } from '@boot/apollo';
import { query, mutation } from '@graphql';
import { getUserLoggedIn, notification } from '@helpers/utils';
import { Icommon } from '@models/general';
import { setPermissionsCache } from '@helpers/cache';
import { ActionTree } from 'vuex';
import { $route } from 'src/router';

export const actions: ActionTree<Icommon, any> = {
  async REQUEST_CONFIG_INITIAL({ commit }, meta: Icommon): Promise<void> {
    const userLoggedIn: Icommon = getUserLoggedIn();
    const userId = userLoggedIn?.user?._id;
    await $apollo
      .query({
        query: query.GET_REQUEST_CONFIG,
        variables: { userId }
      })
      .then(
        async ({
          data: { getUserPermissions, getUserNotification }
        }): Promise<void> => {
          // Permissão
          const permissions = getUserPermissions.permissions.filter(
            (item: string) => {
              return item.charAt(0) !== item.charAt(0).toUpperCase();
            }
          );

          setPermissionsCache({
            accessData: getUserPermissions.accessData,
            accessConfig: getUserPermissions.accessConfig,
            supportRemote: getUserPermissions.supportRemote,
            master: getUserPermissions.master,
            teamsGateway: getUserPermissions.teamsGateway,
            permissions
          });

          const viewPermission = meta.view_permission;
          const master = getUserPermissions.master;
          const checkExistPermission = permissions.indexOf(viewPermission);

          if (!master && checkExistPermission === -1) {
            await $route.push({ name: '403' });
          }

          // Notificação
          commit('SET_ANNOUNCEMENT', getUserNotification.notification);
        }
      )
      .catch(() => {
        notification('error');
      });
  },
  async CLOSE_ANNOUNCEMENT({ state, commit }): Promise<void> {
    const { user }: Icommon = getUserLoggedIn();
    const userId = user._id;
    const notificationId = state.announcement._id;

    await $apollo
      .mutate({
        mutation: mutation.CLOSE_NOTIFICATION,
        variables: { userId, notificationId }
      })
      .finally(() => {
        commit('SET_ANNOUNCEMENT', {});
      });
  }
};
