import { generatePaginate } from '@helpers/utils';
import { Icommon, IPagination } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  ADD_ITEM_SELECT_FIELDS(state, value) {
    state.select_additional_fields.push({ ...value });
  },
  ADD_LIST_SELECT_FIELDS(state, value) {
    state.select_additional_fields = [
      ...state.select_additional_fields,
      ...value
    ];
  },
  SET_LIST_SELECT_FIELDS(state, value) {
    state.select_additional_fields = value;
  },
  SET_PAGINATION_SELECT_FIELDS(state, value: IPagination) {
    state.pagination.selectAdditionalFields = generatePaginate(value);
  }
};
