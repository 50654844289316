import { generatePaginate } from '@helpers/utils';
import { formValidate } from '@helpers/validate';
import { Icommon } from '@models/general';

export const state: Icommon = {
  openModalRegister: false,
  openModalRules: {
    openModal: false,
    id: ''
  },
  openModalCustomization: {
    openModal: false,
    channel: {}
  },
  webChatAtivation: {
    openModal: false,
    id: '',
    version: 'V1'
  },
  modalTestScript: false,
  platformActive: {},
  listPlatforms: [],
  forticsBsps: [],
  techProviderBsps: [],
  listBsps: [],
  listChannels: [],
  listAccountPages: [],
  listRaPages: [],
  listStatus: [],
  infoQrcode: {
    openModal: false,
    id: ''
  },
  pagination: {
    channels: generatePaginate()
  },
  sendForm: {},
  formRules: formValidate(),
  loading: false,
  loadingModal: false
};
