import { $apollo } from '@boot/apollo';
import { mutation, query } from '@graphql';
import { socketMessage } from '@helpers/socket';
import sz_lang from '@helpers/translate';
import {
  getClientId,
  notification,
  openPermissionDialog360,
  openOauthAuthentication,
  removeEmptyAttributes,
  requestPagination,
  supportRemotePermissionRequest
} from '@helpers/utils';
import { getListPlatformHub } from '@helpers/platforms';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';
import { Notify } from 'quasar';
import { legacySZ } from '@boot/axios';
const { translate } = sz_lang();

export const actions: ActionTree<Icommon, any> = {
  async ASYNC_TEMPLATES({ commit }): Promise<void> {
    await legacySZ.get('hsm/sync').finally(() => {
      commit('SET_LOADING', false);
    });
  },
  START_SOCKET({ commit }) {
    const client_id = getClientId();
    socketMessage({ query: `status=${client_id}` }, message => {
      commit('SET_STATUS_QRCODE', message);
    });
  },
  async LOAD_REQUEST_ALL({ commit }): Promise<void> {
    commit('SET_LOADING', true);

    if (supportRemotePermissionRequest()) {
      commit('SET_LOADING', false);
      return;
    }

    await $apollo
      .query({ query: query.GET_CHANNELS })
      .then(
        ({
          data: {
            channels,
            additionalFields,
            getBSPs,
            hubFacebook,
            facebookAdAccounts,
            getPlatformsDefault,
            getBSPForticsNumbers,
            getTechProviderNumbers
          }
        }) => {
          const root = { root: true };
          const fields = [...additionalFields.items];
          const listAccountPages = [...facebookAdAccounts];
          const {
            instagramComments,
            instagramDirect,
            facebookMessenger,
            facebookPost
          } = getListPlatformHub(hubFacebook);
          const platforms = getPlatformsDefault.map((item: Icommon) => {
            return {
              label: item.name,
              icon: item.icon,
              platform: item.platform,
              in_channel_pack: item.in_channel_pack
            };
          });
          // Channels
          commit('SET_LIST_CHANNELS', channels.items);
          commit('SET_PAGINATION_CHANNELS', channels.meta);
          // platforms
          commit('SET_LIST_PLATFORMS', platforms);
          // BSPS
          commit('SET_FORTICS_BSPS', getBSPForticsNumbers);
          commit('SET_TECH_PROVIDERS_BSPS', getTechProviderNumbers);
          commit('SET_LIST_BSPS', getBSPs);
          // ad acoount instagram
          commit('SET_AD_ACCOUNT_PAGES', listAccountPages);
          // Instagram
          commit(
            'InstagramModule/SET_LIST_PAGES_DIRECT',
            instagramDirect,
            root
          );
          commit(
            'InstagramModule/SET_LIST_PAGES_COMMENTS',
            instagramComments,
            root
          );
          // Facebook Post
          commit('FacebookModule/SET_LIST_PAGES_POST', facebookPost, root);
          commit(
            'FacebookModule/SET_LIST_PAGES_MESSENGER',
            facebookMessenger,
            root
          );
          // Campos adicionais
          commit('AdditionalFieldsModule/SET_LIST_SELECT_FIELDS', fields, root);
          commit(
            'AdditionalFieldsModule/SET_PAGINATION_SELECT_FIELDS',
            additionalFields.meta,
            root
          );
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async GET_LIST_SELECT_CHANNELS({ commit }): Promise<void> {
    commit('SET_LOADING_SELECT', true);

    await $apollo
      .query({
        query: query.GET_CHANNELS_SELECT
      })
      .then(({ data: { channels } }) => {
        commit('SET_LIST_CHANNELS', channels.items);
        commit('SET_PAGINATION_CHANNELS', channels.meta);
      })
      .finally(() => {
        commit('SET_LOADING_SELECT', false);
      });
  },
  async SEARCH_SELECT_CHANNELS({ commit, state }, search): Promise<void> {
    if (search && search.length >= 3) {
      await $apollo
        .query({
          query: query.GET_SEARCH_CHANNELS_SELECT,
          variables: { search }
        })
        .then(({ data: { channels } }) => {
          const { items } = channels;
          items.forEach((channel: Icommon) => {
            const index = state.listChannels.findIndex(
              (item: Icommon) => item._id === channel._id
            );

            if (index === -1) {
              commit('ADD_ITEM_SELECT_CHANNELS', channel);
            }
          });
        });
    }
  },
  async VIRTUAL_SCROLL_SELECT_CHANNELS(
    { commit, state },
    { direction, to, ref }
  ) {
    const pagination = state.pagination.channels;
    const lastIndex = state.listChannels.length - 1;
    if (
      state.loadingSelect !== true &&
      direction === 'increase' &&
      to === lastIndex
    ) {
      const limit = pagination.itemsPerPage;
      const page_next = +pagination.currentPage + 1;
      let page = 0;

      if (page_next <= pagination.totalPages) {
        page = page_next;
      } else {
        return false;
      }

      commit('SET_LOADING_SELECT', true);

      await $apollo
        .query({
          query: query.GET_CHANNELS_SELECT,
          variables: {
            page,
            limit
          }
        })
        .then(({ data: { channels } }) => {
          commit('SET_LIST_CHANNELS', channels.items);
          commit('SET_PAGINATION_CHANNELS', channels.meta);
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
          ref.refresh();
        });
    }
  },
  async SEARCH_CHANNELS({ commit }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .query({ query: query.SEARCH_CHANNELS, variables: { ...params } })
      .then(({ data: { channels } }) => {
        commit('SET_LIST_CHANNELS', channels.items);
        commit('SET_PAGINATION_CHANNELS', channels.meta);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async GET_GUPSHUP_CREDENTIALS({}, params: Icommon) {
    return await $apollo.query({
      query: query.GET_GUPSHUP_CREDENTIALS,
      variables: {
        whatsappBrokerSelected: params.whatsappBrokerSelected,
        channelId: params.channelId
      }
    });
  },
  async REQUEST_PAGINATION({ commit, state }, params): Promise<void> {
    commit('SET_LOADING', true);
    const getParams = params || { page: 1 };

    await requestPagination(query.GET_CHANNELS_PAGINATION, {
      ...getParams,
      limit: state.pagination.channels.itemsPerPage
    })
      .then(({ data: { channels } }) => {
        commit('SET_LIST_CHANNELS', channels.items);
        commit('SET_PAGINATION_CHANNELS', channels.meta);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async REQUEST_WEBHOOK({}, param): Promise<Icommon> {
    return await $apollo.mutate({
      mutation: query.GET_WEBHOOK_URL,
      variables: { ...param }
    });
  },
  async REQUEST_CREDENTIALS_OAUTH2({}, param): Promise<Icommon> {
    return await $apollo.mutate({
      mutation: query.GET_CREDENTIALS_OAUTH2,
      variables: param
    });
  },
  async REQUEST_GENERATE_TOKEN(
    { dispatch, commit },
    id: string
  ): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({
        mutation: mutation.GENERATE_TOKEN,
        variables: { id }
      })
      .then(
        async (): Promise<void> => {
          notification('success');
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async REQUEST_GENERATE_CHANNEL_APIKEY(
    { commit },
    params: any
  ): Promise<void> {
    const { number, partnerId } = params;
    commit('SET_LOADING', true);
    return await legacySZ
      .get('channels/add/redirectUrl')
      .then(async ({ data }) => {
        if (data.status) {
          const res: any = await openPermissionDialog360(number, partnerId);
          commit('SET_LOADING', false);

          if (res.status) {
            return res.channelsApiKey;
          }

          notification('error');
          notification('error', { message: { ...res } });
        } else {
          notification('error', {
            message: translate.value.errors[data.message]
          });
        }
      });
  },
  async REQUEST_GENERATE_APIKEY({}, channelsApiKey: string): Promise<void> {
    return await legacySZ({
      headers: { 'Content-Type': 'application/json' },
      method: 'get',
      url: 'channels/dialog/generateApiKey',
      params: { channelsApiKey }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        notification('error', {
          message: translate.value.errors[error.message]
        });
      });
  },
  async RESTART_CHANNEL({ commit }, id: string): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({ mutation: mutation.RESTART_CHANNEL, variables: { id } })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async GET_RA_PAGES({ commit }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .query({
        query: query.GET_RA_PAGES,
        variables: { ...params }
      })
      .then(({ data }) => {
        if (data.reclameAquiGetPages?.status) {
          commit('SET_RA_PAGES', data.reclameAquiGetPages.pages);
          notification('success', { message: translate.value.ra_success });
        } else {
          notification('error', {
            message: translate.value.errors.ra_auth_error
          });
        }
      })
      .catch(() => {
        notification('error', {
          message: translate.value.errors.ra_auth_error
        });
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async ENABLE_CHANNEL({ commit, dispatch }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({ mutation: mutation.ENABLE_CHANNEL, variables: { ...params } })
      .then(
        async ({ data: { toggleEnableChannel } }): Promise<void> => { 
          if(toggleEnableChannel.success ){
            notification('success')
          } else {
            notification('error')
          }
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async ENABLE_RECEPTIVE({ dispatch, commit }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({ mutation: mutation.ENABLE_RECEPTIVE, variables: { ...params } })
      .then(
        async (): Promise<void> => {
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async CHECK_ACCOUNT_DIRECT({ commit }, channelId: string): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .query({
        query: query.CHECK_ACCOUNT,
        variables: { channelId }
      })
      .then(({ data: { isInstagramBusiness } }) => {
        const data = isInstagramBusiness;
        let icon = 'fas fa-info-circle text-grey';
        let message = data.message;

        if (data.status) {
          icon = 'fas fa-check-circle text-green';
          message = translate.value.channel_screen.messages.check_account;
        }

        Notify.create({
          message: message,
          color: 'white',
          classes: 'sz_notify',
          icon: icon,
          position: 'center',
          timeout: 0,
          actions: [
            {
              label: 'OK',
              color: 'green'
            }
          ]
        });
      })
      .catch(() => {
        notification('error');
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async CREATE_RULES_EMAIL({ commit, dispatch }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({ mutation: mutation.ADD_RULES_EMAIL, variables: { ...params } })
      .then(
        async (): Promise<void> => {
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async CREATE_OR_UPDATE_CHANNEL({ commit, dispatch }, params): Promise<void> {
    const id = params._id;
    let route = mutation.CREATE_CHANNELS;
    let configParams: Icommon = {
      channel: { ...removeEmptyAttributes(params) }
    };
    let openAuth = true;

    if (id) {
      route = mutation.UPDATE_CHANNELS;
      delete params._id;
      configParams = { channel: params, id };
      openAuth = false;
    }

    commit('SET_LOADING_MODAL', true);

    await $apollo
      .mutate({
        mutation: route,
        variables: configParams
      })
      .then(
        async ({ data }): Promise<void> => {
          const authUrl =
            data.createChannel?.authUrl || data.updateChannel?.authUrl;

          if (authUrl && openAuth) {
            await dispatch('OPEN_SIGNIN', {
              path: authUrl,
              originalUrl: window.location.origin,
              callback: async () => {
                await dispatch('REQUEST_PAGINATION');
              }
            });
          }

          if (data.updateChannel?.status == 'pending_auth') {
            notification('warning', {
              message: 'OPS!',
              caption: translate.value.messages.reauthentication_required
            });
          }

          notification('success');
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .catch(error => {
        switch (error.message) {
          case 'flow_does_not_exist':
            notification('warning', {
              message: 'OPS!',
              caption: translate.value.errors.flow_not_exist
            });
            break;
          case 'invalid_flow_components':
            notification('warning', {
              message: 'OPS!',
              caption: translate.value.messages.flow_invalid
            });
            break;
          case 'license_limit_per_channel_reached':
            notification('warning', {
              message: 'OPS!',
              caption:
                translate.value.messages.licence_limit_per_channel_reached
            });
            break;
          case 'channel_already_exists_in_another_licence':
            notification('warning', {
              message: 'OPS!',
              caption:
                translate.value.messages
                  .channel_already_exists_in_another_licence
            });
            break;
          case 'email_settings_not_found':
            notification('warning', {
              message: 'OPS!',
              caption: translate.value.messages.email_settings_not_found
            });
            break;
          default:
            notification('error');
            break;
        }
      })
      .finally(() => {
        commit('SET_LOADING_MODAL', false);
      });
  },
  OPEN_SIGNIN({}, options): void {
    options.windowName = options.windowName || 'ConnectWithOAuth'; // should not include space for IE
    options.windowOptions =
      options.windowOptions ||
      'location=0, status=0, toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
    options.callback =
      options.callback ||
      function() {
        window.location.reload();
      };

    const oauthWindow = window.open(
      options.path,
      options.windowName,
      options.windowOptions
    );
    const _oauthInterval = window.setInterval(() => {
      if (oauthWindow?.location.origin === options.originalUrl) {
        window.clearInterval(_oauthInterval);
        options.callback();
        oauthWindow?.close();
      }
    }, 1000);
  },
  async REQUEST_GENERATE_CODE({}, options): Promise<void> {
    const res: any = await openOauthAuthentication('', options);
    if (res.status) {
      return res.code;
    } else {
      notification('error', {
        message: translate.value.messages.error_email
      });
    }
  },
  async SAVE_CUSTOMIZATION_V1(
    { commit, dispatch },
    { options, id }
  ): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({
        mutation: mutation.ADD_CUSTOMIZATION_V1,
        variables: {
          options: { ...options, label: JSON.stringify(options.label) },
          id
        }
      })
      .then(
        async (): Promise<void> => {
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async SAVE_CUSTOMIZATION_V2(
    { commit, dispatch },
    { options, id }
  ): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({
        mutation: mutation.ADD_CUSTOMIZATION_V2,
        variables: { options: { ...options }, id }
      })
      .then(
        async (): Promise<void> => {
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async DELETE_CHANNEL({ commit, dispatch }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({
        mutation: mutation.DELETE_CHANNELS,
        variables: { channel: { ids: params } }
      })
      .then(async ({ data: { removeChannel } }: Icommon) => {
        if (!removeChannel.success) {
          notification('error', {
            message:
              translate.value.channel_screen.messages
                .session_open_channel_remove
          });
          return false;
        } else {
          notification('success');
          await dispatch('REQUEST_PAGINATION');
        }
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async TEST_SCRIPT_COMPONENT({}, newParams): Promise<void> {
    const params = {
      params: newParams.params,
      type: newParams.type,
      code: newParams.code,
      expression: newParams.expression
    };

    const { data } = await legacySZ.get('services/script/testingScript', {
      params
    });

    return data;
  }
};
