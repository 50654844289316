import sz_lang from '@helpers/translate';
import { Icommon, Iselect } from '@models/general';
import { GetterTree } from 'vuex';
const { translate } = sz_lang();

export const getters: GetterTree<Icommon, any> = {
  methodOptions: (): Iselect[] => {
    return [
      { label: 'GET', value: 'GET' },
      { label: 'POST', value: 'POST' },
      { label: 'PUT', value: 'PUT' },
      { label: 'PATCH', value: 'PATCH' },
      { label: 'DELETE', value: 'DELETE' }
    ];
  },
  controlOptions: (): Iselect[] => {
    return [
      { label: translate.value.post, value: 'post' },
      { label: translate.value.comment, value: 'comment' }
    ];
  },
  controlYtOptions: (): Iselect[] => {
    return [
      { label: translate.value.hsm_screen.head_type_video, value: 'post' },
      { label: translate.value.comment, value: 'comment' }
    ];
  },
  periodsOptions: (): Iselect[] => {
    const days = ['0', '30', '60', '90'];
    const text_days: string = translate.value.days.toString();
    return days.map(
      (item: string | number): Iselect => {
        return {
          label: `${item}  ${text_days}`,
          value: item
        };
      }
    );
  },
  pageOptions: () => {
    return [];
  },
  accountNameOptions: () => {
    return [];
  },
  protocolOptions: () => {
    return [
      { value: 'SSL', label: 'SSL', port: '465' },
      { value: 'TLS', label: 'TLS', port: '587' }
    ];
  },
  countryOptions: () => {
    return [
      { value: 'brasil', label: 'Brasil' },
      { value: 'mexico', label: 'México' },
      { value: 'argentina', label: 'Argentina' }
    ];
  },
  OAuthOptions: () => {
    return [
      { value: 'password', label: translate.value.password },
      { value: 'oauth', label: 'OAuth' }
    ];
  },
  typeOptions: (): Iselect[] => {
    return [
      { value: 'hour', label: translate.value.hour },
      { value: 'hash', label: translate.value.hash }
    ];
  },
  providerOptions: (): Iselect[] => {
    return [
      {
        label: 'Gmail',
        value: 'gmail'
      },
      {
        label: 'Yahoo',
        value: 'yahoo'
      },
      {
        label: 'Office 365',
        value: 'office365'
      },
      {
        label: 'Gmail OAuth',
        value: 'gmail_oauth'
      },
      {
        label: 'Office 365 OAuth',
        value: 'office365_oauth'
      },
      {
        label: translate.value.other,
        value: 'other'
      }
    ];
  },
  configImap: (): Icommon[] => {
    return [
      {
        provider: 'gmail',
        host: 'imap.gmail.com',
        port: 993,
        protocol: 'SSL'
      },
      {
        provider: 'office365',
        host: 'outlook.office365.com',
        port: 993,
        protocol: 'TLS'
      },
      {
        provider: 'yahoo',
        host: 'imap.mail.yahoo.com',
        port: 993,
        protocol: 'SSL'
      }
    ];
  },
  configSmtp: (): Icommon[] => {
    return [
      {
        provider: 'gmail',
        host: 'smtp.gmail.com',
        port: 465,
        protocol: 'SSL'
      },
      {
        provider: 'office365',
        host: 'smtp.office365.com',
        port: 587,
        protocol: 'TLS'
      },
      {
        provider: 'yahoo',
        host: 'smtp.mail.yahoo.com',
        port: 465,
        protocol: 'SSL'
      }
    ];
  },

  customization: () => {
    return {
      confirmOptions: [
        { label: translate.value.yes, value: true },
        { label: translate.value.no, value: false }
      ],
      fontColors: [
        {
          value: 'light',
          label: translate.value.light
        },
        {
          value: 'dark',
          label: translate.value.dark
        }
      ],
      position: [
        { label: translate.value.right, value: 'right' },
        { label: translate.value.left, value: 'left' }
      ],
      labelColors: [
        { label: translate.value.list_colors.red, value: 'red' },
        { label: translate.value.list_colors.violet, value: 'violet' },
        { label: translate.value.list_colors.yellow, value: 'yellow' },
        { label: translate.value.list_colors.green, value: 'green' },
        { label: translate.value.list_colors.blue, value: 'blue' },
        { label: translate.value.list_colors.pink, value: 'pink' },
        { label: translate.value.list_colors.purple, value: 'purple' },
        { label: translate.value.list_colors.grey, value: 'grey' },
        { label: translate.value.list_colors.brown, value: 'brown' },
        { label: translate.value.list_colors.black, value: 'black' }
      ],
      animationOptionsV1: [
        { label: 'Nenhum', value: 'none' },
        { label: 'Jiggle', value: 'jiggle' },
        { label: 'Flash', value: 'flash' },
        { label: 'Shake', value: 'shake' },
        { label: 'Pulse', value: 'pulse' },
        { label: 'Tada', value: 'tada' },
        { label: 'Bounce', value: 'bounce' }
      ],
      userColors: ['#00A5A8', '#0b82f7', '#607d8b'],
      animationOptions: [
        {
          value: 'slide',
          label: translate.value.slide
        },
        {
          value: 'scale',
          label: translate.value.increase
        },
        {
          value: 'fade',
          label: translate.value.gradually_appear
        },
        {
          value: 'scale-rotate',
          label: translate.value.increase_rotated
        }
      ],
      themes: [
        '#197fbc',
        '#18c139',
        '#383d45',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff5722',
        '#795548',
        '#9e9e9e',
        '#607d8b'
      ],
      buttonColors: [
        '#197fbc',
        '#18c139',
        '#383d45',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#ff5722',
        '#795548',
        '#9e9e9e',
        '#607d8b'
      ],
      listIcons: [
        { label: 'fas fa-paper-plane', value: 'paper plane' },
        { label: 'fas fa-envelope', value: 'envelope' },
        { label: 'fas fa-comment', value: 'comment' },
        { label: 'fas fa-comments', value: 'comments' },
        { label: 'fas fa-comment-dots', value: 'comment alternate' },
        { label: 'fas fa-home', value: 'home' },
        { label: 'fas fa-cog', value: 'cog' },
        { label: 'fas fa-cogs', value: 'cogs' },
        { label: 'fas fa-lightbulb', value: 'lightbulb' },
        { label: 'fas fa-bell', value: 'bell' }
      ],
      types: [
        { label: translate.value.image, value: 'image' },
        { label: translate.value.button, value: 'button' },
        { label: translate.value.text, value: 'text' }
      ],
      fieldTypes: [
        {
          key: 'text',
          value: 'text',
          label: translate.value.text
        },
        {
          key: 'number',
          value: 'number',
          label: translate.value.number
        },
        {
          key: 'email',
          value: 'email',
          label: translate.value.email
        },
        {
          key: 'tel',
          value: 'tel',
          label: 'Telefone'
        },
        {
          key: 'cpf',
          value: 'cpf',
          label: 'CPF'
        },
        {
          key: 'cnpj',
          value: 'cnpj',
          label: 'CNPJ'
        }
      ],
      listFieldsPredefined: [
        {
          name: 'name',
          label: translate.value.name,
          customLabel: null,
          required: true,
          type: 'text',
          checked: true,
          typeVariable: 'predefined'
        },
        {
          name: 'email',
          label: translate.value.email,
          customLabel: null,
          required: false,
          type: 'email',
          checked: true,
          typeVariable: 'predefined'
        },
        {
          name: 'number',
          label: 'Telefone',
          customLabel: null,
          required: false,
          type: 'tel',
          checked: false,
          typeVariable: 'predefined'
        }
      ]
    };
  }
};
